<template>
  <div class="all-height d-flex justify-center">
    <div class="all-width">
      <!-- <div class="d-flex align-center">
        <v-btn class="shadow-off mb-4" text @click="$nova.gotoLink({path: '/pbc/requestee/view/'+client+'/'+code})"><v-icon left>mdi-arrow-left</v-icon> Back to Dashboard</v-btn>
        <v-spacer></v-spacer>
        <v-btn class="shadow-off mb-4" text @click="refreshData()"><v-icon left>mdi-refresh</v-icon>Refresh</v-btn>
      </div> -->
      <v-card loader-height="2" :loading="loading" :disabled="loading">
        <v-card-text class="pb-0">
          <div class="d-flex align-center" v-if="!embed">
            <v-tooltip bottom content-class="tooltip-bottom">
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" class="shadow-off" icon small @click="$nova.gotoLink({path: '/pbc/requestee/view/'+client+'/'+code})"><v-icon>mdi-arrow-left</v-icon></v-btn>
              </template>
              <span>Back to projects</span>
            </v-tooltip>
            <v-tooltip bottom content-class="tooltip-bottom">
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" class="shadow-off" icon small @click="refreshData()"><v-icon>mdi-refresh</v-icon></v-btn>
              </template>
              <span>Refresh data</span>
            </v-tooltip>
          </div>
        </v-card-text>
        <v-card-text class="d-flex align-center pb-0">
          <v-progress-circular
            :rotate="270"
            :size="70"
            :width="12"
            :value="progress"
            color="success"
          >
            {{ progress }}%
          </v-progress-circular>
          <div class="ml-4">
            <div class="subtitle-1 font-weight-bold">{{data.name}}</div>
            <div class="d-flex align-center">
              <div class="">
                <span class="font-weight-bold">Due Date: </span>
                {{$nova.formatDate(data.duedate)}}
              </div>
              <v-icon>mdi-circle-small</v-icon>
              <span label v-if="(Math.floor((new Date(data.duedate) - new Date())/(60*60*24*1000)) < 0)" class="error--text">{{( Math.floor((new Date(data.duedate) - new Date())/(60*60*24*1000))*-1)}} days overdue</span>
              <span label v-else class="info--text">{{( Math.floor((new Date(data.duedate) - new Date())/(60*60*24*1000)))}} days remaining</span>
              <!-- <v-chip label class="caption ml-1 px-2" :color="(Math.floor((new Date(data.duedate) - new Date())/(60*60*24*1000)) < 0)?'error':'info'" small>{{ Math.floor((new Date(data.duedate) - new Date())/(60*60*24*1000))}} Days</v-chip> -->
            </div>
          </div>
          <v-spacer></v-spacer>
          <div>
            <div class="d-flex align-center">
              <div class="d-flex align-center primary--text mr-4">
                <span class="display-1 font-weight-bold">{{totalrequests}}</span>
                <span class="body-2 ml-1 line-1">Total<br/>Requests</span>
              </div>
              <div class="d-flex align-center info--text mx-4">
                <span class="display-1 font-weight-bold">{{(data.summary || {}).requested || 0}}</span>
                <span class="body-2 ml-1 line-1">Yet to Respond<br/>Count</span>
              </div>
              <div class="d-flex align-center warning--text mx-4">
                <span class="display-1 font-weight-bold">{{(data.summary || {}).pending || 0}}</span>
                <span class="body-2 ml-1 line-1">Waiting for Approval<br/>Count</span>
              </div>
              <div class="d-flex align-center error--text mx-4">
                <span class="display-1 font-weight-bold">{{(data.summary || {}).cancelled || 0}}</span>
                <span class="body-2 ml-1 line-1">Cancelled<br/>Count</span>
              </div>
            </div>
          </div>
        </v-card-text>
        <!-- <v-card-text>
          <v-row>
            <v-col cols="12" sm="6" md="3" class="pb-0">
              <v-alert color="primary lighten-5" text class="py-2">
                <div class="d-flex align-center primary--text">
                  <span class="px-4 display-1 font-weight-bold">{{totalrequests}}</span>
                  <span>Total Requests</span>
                </div>
              </v-alert>
            </v-col>
            <v-col cols="12" sm="6" md="3" class="pb-0">
              <v-alert color="primary lighten-5" text class="py-2">
                <div class="d-flex align-center info--text">
                  <span class="px-4 display-1 font-weight-bold">{{(data.summary || {}).requested || 0}}</span>
                  <span class="subtitle-1">Yet to Respond</span>
                </div>
              </v-alert>
            </v-col>
            <v-col cols="12" sm="6" md="3" class="pb-0">
              <v-alert color="primary lighten-5" text class="py-2">
                <div class="d-flex align-center warning--text">
                  <span class="px-4 display-1 font-weight-bold">{{(data.summary || {}).pending || 0}}</span>
                  <span class="subtitle-1">Waiting for Approval</span>
                </div>
              </v-alert>
            </v-col>
            <v-col cols="12" sm="6" md="3" class="pb-0">
              <v-alert color="primary lighten-5" text class="py-2">
                <div class="d-flex align-center error--text">
                  <span class="px-4 display-1 font-weight-bold">{{(data.summary || {}).cancelled || 0}}</span>
                  <span class="subtitle-1">Cancelled</span>
                </div>
              </v-alert>
            </v-col>
          </v-row>
        </v-card-text> -->
        <v-card-text class="">
          <!-- <div class="d-flex align-center mb-4">
            <div class="font-weight-bold mr-4 body-2">Filter: </div>
            <v-checkbox label="Pending" class="mt-0 mr-4 info--text" dense hide-details color="info" v-model="stagefilter" multiple :value="0"></v-checkbox>       
            <v-checkbox label="Pending Approval" class="mt-0 mr-4 warning--text" dense hide-details color="warning" v-model="stagefilter" multiple :value="1"></v-checkbox>       
            <v-checkbox label="Cancelled" class="mt-0 mr-4 error--text" dense hide-details color="error" v-model="stagefilter" multiple :value="-1"></v-checkbox>       
            <v-checkbox label="Closed" class="mt-0 mr-4 success--text" dense hide-details color="success" v-model="stagefilter" multiple :value="2"></v-checkbox>       
            <v-spacer></v-spacer>
            <v-chip label outlined class="border-left-error" small>Urgent</v-chip>
          </div> -->
          <v-simple-table dense class="pbc-view-table" v-if="requestlist.length > 0 && counter > 0">
            <thead>
              <tr>
                <th scope="#" class="px-4 text-center">#</th>
                <th scope="type" class="text-center" style="max-width:40px">Type</th>
                <th scope="urgent" class="text-center" style="max-width:20px"></th>
                <th scope="Category">
                  <div class="d-flex align-center">
                    <span>Category</span>
                    <v-menu offset-y transition="slide-y-transition" :close-on-content-click="false" dense>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn class="mx-0 px-0" small icon v-bind="attrs" v-on="on"><v-icon>mdi-filter-menu</v-icon></v-btn>
                      </template>
                      <v-list dense class="border-left-default py-0">
                        <v-list-item class="py-0 px-0 my-0" v-for="(v,k) in categorylist" :key="k">
                          <v-list-item-title class="pa-2">
                            <div class="d-flex align-center pr-2">
                              <v-checkbox label="" class="mt-0 pt-0" color="grey darken-2" dense hide-details v-model="categoryfilter" multiple :value="v"></v-checkbox>
                              <span class="">{{v}}</span>
                            </div>
                          </v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </div>
                </th>
                <th scope="description">Description</th>
                <th scope="response">Response</th>
                <th scope="due-date" class="text-center">Due Date</th>
                <!-- <th scope="expcted-date" class="text-center">Your Expected Date</th> -->
                <th scope="action" class="text-center">Action</th>
                <th scope="status" class="text-center">
                  <span>Status</span><br/>
                  <div class="d-flex justify-center">
                    <v-btn icon small @click="statushelpdialog=true"><v-icon>mdi-help-circle</v-icon></v-btn>
                    <v-menu offset-y transition="slide-y-transition" :close-on-content-click="false" dense>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn class="mx-0 px-0" small icon v-bind="attrs" v-on="on"><v-icon>mdi-filter-menu</v-icon></v-btn>
                      </template>
                      <v-list dense class="border-left-default py-0">
                        <v-list-item class="py-0 px-0 my-0">
                          <v-list-item-title class="pa-2">
                            <div class="d-flex align-center">
                              <v-checkbox label="" class="mt-0 pt-0" color="grey darken-2" dense hide-details v-model="stagefilter" multiple :value="0"></v-checkbox>
                              <v-icon class="info--text" left>mdi-timer-sand</v-icon>
                              <span class="info--text">Waiting Repsonse</span>
                            </div>
                          </v-list-item-title>
                        </v-list-item>
                        <v-list-item class="py-0 px-0 my-0">
                          <v-list-item-title class="pa-2">
                            <div class="d-flex align-center">
                              <v-checkbox label="" class="mt-0 pt-0" color="grey darken-2" dense hide-details v-model="stagefilter" multiple :value="1"></v-checkbox>
                              <v-icon class="warning--text" left>mdi-timer-sand</v-icon>
                              <span class="warning--text">Pending Approval</span>
                            </div>
                          </v-list-item-title>
                        </v-list-item>
                        <v-list-item class="py-0 px-0 my-0">
                          <v-list-item-title class="pa-2">
                            <div class="d-flex align-center">
                              <v-checkbox label="" class="mt-0 pt-0" color="grey darken-2" dense hide-details v-model="stagefilter" multiple :value="2"></v-checkbox>
                              <v-icon class="success--text" left>mdi-check-circle</v-icon>
                              <span class="success--text">Closed Request</span>
                            </div>
                          </v-list-item-title>
                        </v-list-item>
                        <v-list-item class="py-0 px-0 my-0">
                          <v-list-item-title class="pa-2">
                            <div class="d-flex align-center">
                              <v-checkbox label="" class="mt-0 pt-0" color="grey darken-2" dense hide-details v-model="stagefilter" multiple :value="-1"></v-checkbox>
                              <v-icon class="" left>mdi-cancel</v-icon>
                              <span class="">Cancelled Request</span>
                            </div>
                          </v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              <template v-for="(v,k) in requestlist">
                <tr v-if="stagefilter.indexOf(v.stage) > -1 && categoryfilter.indexOf(v.category) > -1" :key="k" class="hoverable">
                  <td :class="`text-center px-0 mx-0 ${v.urgent?'border-left-error':''}`">
                    <span>{{k+1}}</span>
                  </td>
                  <td class="text-center" style="max-width:40px">
                    <v-tooltip right content-class="tooltip-right" v-if="v.type==='text'">
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon v-bind="attrs" v-on="on">mdi-text-short</v-icon>
                      </template>
                      <span>Text Request</span>
                    </v-tooltip>
                    <v-tooltip right content-class="tooltip-right" v-else-if="v.type==='fileupload'">
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon v-bind="attrs" v-on="on">mdi-file-upload</v-icon>
                      </template>
                      <span>File Upload Request</span>
                    </v-tooltip>
                    <v-tooltip right content-class="tooltip-right" v-else-if="v.type==='fileupload'">
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon v-bind="attrs" v-on="on">mdi-upload-network</v-icon>
                      </template>
                      <span>File Upload to a link Request</span>
                    </v-tooltip>
                  </td>
                  <td class="text-center" style="max-width:20px">
                    <v-tooltip bottom content-class="tooltip-bottom" v-if="v.urgent">
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon v-bind="attrs" v-on="on" color="error">mdi-alert-circle</v-icon>
                      </template>
                      <span>Urgent</span>
                    </v-tooltip>
                  </td>
                  <td>{{v.category}}</td>
                  <td>{{v.description}}</td>
                  <td class="response-td">
                    <div class="d-flex align-center">
                      <div v-if="v.type==='fileuploadtolink'" class="mr-2">
                        <div v-if="v.response">
                          File Uploaded
                          <v-btn small icon :href="v.uploadlink" target="_blank" class="shadow-off ml-2"><v-icon>mdi-launch</v-icon></v-btn>
                        </div>
                        <div v-else>NA</div>
                        <!-- <v-btn v-if="v.response" small outlined text :href="v.uploadlink" target="_blank" class="shadow-off">Folder<v-icon right>mdi-launch</v-icon></v-btn> -->
                      </div>
                      <div v-else-if="v.type==='link'" class="mr-2">
                        <v-btn v-if="v.response" small outlined text :href="(v.response.substring(0, 9).search('http://') > -1 || v.response.substring(0, 9).search('https://') > -1)?v.response:('//'+v.response)" target="_blank" class="shadow-off">Link<v-icon right>mdi-launch</v-icon></v-btn>
                      </div>
                      <div v-else-if="v.type==='fileupload'" class="d-flex align-center">
                        <div v-if="v.fileid">
                          File Uploaded
                          <v-btn small icon class="shadow-off ml-2" @click="$nova.downloadFile(v.fileid, {pbccode: code, client: client, type: 'pbcrequest'})"><v-icon>mdi-download</v-icon></v-btn>
                        </div>
                        <div v-else>NA</div>
                        <!-- <v-btn v-if="v.fileid" small outlined text class="shadow-off" @click="$nova.downloadFile(v.fileid, {pbccode: code, client: client, type: 'pbcrequest'})"><v-icon>mdi-download</v-icon></v-btn>
                        <span v-else>Not yet uploaded</span> -->
                      </div>
                      <div v-else-if="v.type==='multiselect'" class="mr-2 response-td-text">{{((v.response instanceof Array)?v.response:[]).join(", ") || v.response}}</div>
                      <div v-else class="mr-2 response-td-text">{{v.response || ''}}</div>
                      <div v-if="v.remark" class="response-td-text"><span class="mx-2">-</span>{{v.remark || ''}}</div>
                    </div>
                  </td>
                  <td class="text-center">
                    <div class="d-flex align-center justify-center">
                      <div class="" v-if="v.expecteddate">
                        {{$nova.formatDate(v.expecteddate)}}
                        <br/>
                        <v-chip v-if="[0,1].indexOf(v.stage) > -1" label class="caption ml-1 px-2" :color="(Math.floor((new Date(v.expecteddate) - new Date())/(60*60*24*1000)) < 0)?'error':''" x-small>{{ Math.floor((new Date(v.expecteddate) - new Date())/(60*60*24*1000))}} Days</v-chip>
                      </div>
                      <div class="" v-else-if="v.duedate">
                        {{$nova.formatDate(v.duedate)}}
                        <br/>
                        <v-chip v-if="[0,1].indexOf(v.stage) > -1" label class="caption ml-1 px-2" :color="(Math.floor((new Date(v.duedate) - new Date())/(60*60*24*1000)) < 0)?'error':''" x-small>{{ Math.floor((new Date(v.duedate) - new Date())/(60*60*24*1000))}} Days</v-chip>
                      </div>
                      <div v-else>NA</div> 
                      <v-tooltip bottom content-class="tooltip-bottom" v-if="new Date() <= new Date(data.duedate)">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn small class="shadow-off ml-2" icon @click="expecteddatekey=k;expecteddatedialog=true;expecteddatevalue=v.expecteddate;expecteddateerror='',addeditresponseheader=v" v-bind="attrs" v-on="on">
                            <v-icon class="">mdi-pencil</v-icon>
                          </v-btn>
                        </template>
                        <span>Add/Edit due date</span>
                      </v-tooltip>
                    </div>
                  </td>
                  <td class="text-center">
                    <!-- <template v-for="(sv,sk) in statustext">
                      <div v-if="sk == 's'+v.stage" :key="sk">
                        <div v-if="data.stage!==0" :class="`${sv.color}--text font-weight-bold full-height d-flex align-center justify-center`" v-bind="attrs" v-on="on">
                          <span>{{sv.text}}</span>
                        </div>
                        <v-menu offset-y :key="sk" v-else transition="slide-y-transition" :disabled="statusloading[v._id]">
                          <template v-slot:activator="{ on, attrs }">
                            <div :class="`${sv.color}--text font-weight-bold full-height d-flex align-center justify-center`" v-bind="attrs" v-on="on">
                              <span>{{sv.text}}</span>
                              <v-btn icon loading x-small class="ml-3" color="grey lighten-1" v-if="statusloading[v._id]"></v-btn>
                              <v-icon loading class="ml-1" color="grey lighten-1" v-else>mdi-chevron-down</v-icon>
                            </div>
                          </template>
                          <v-list dense class="pa-0">
                            <template v-for="(mv, mk) in changestatustext">
                              <v-list-item :key="mk" v-if="mv.stages.indexOf(v.stage) > -1" @click="changeStage(v._id, mv.changeto)">
                                <v-list-item-title>
                                  <span :class="`${mv.color}--text`">{{ mv.text }}</span> 
                                </v-list-item-title>
                              </v-list-item>
                            </template>
                          </v-list>
                        </v-menu>
                      </div>
                    </template> -->
                    <div class="d-flex justify-center">
                      <v-tooltip bottom content-class="tooltip-bottom">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn class="shadow-off" small icon @click="responsedialog=true;addeditresponseheader=v;addeditresponse={response: v.response || '', remark: v.remark || ''}" v-bind="attrs" v-on="on">
                            <v-icon v-if="(v.stage !== 2)" color="">mdi-plus-circle</v-icon>
                            <v-icon v-else color="">mdi-eye</v-icon>
                          </v-btn>
                        </template>
                        <span v-if="(v.stage === 2)">View Response</span>
                        <span v-else>Add/Edit Response</span>
                      </v-tooltip>
                      <v-tooltip bottom content-class="tooltip-bottom">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn @click="markCommentAsRead(v, k);commentreqkey=k;commentsdialog=true;addeditresponseheader=v;commentsdata='';commentserror=''" v-bind="attrs" v-on="on" icon small>
                            <v-badge color="error" offset-x="10" offset-y="10" dot v-if="(v.comments || []).length > 0 && (((v.comments || [])[(v.comments || []).length - 1] || {}).readby || []).indexOf(v.info_owner) === -1">
                              <v-icon class="">mdi-comment-text-multiple</v-icon>
                            </v-badge>
                            <v-icon v-else class="">mdi-comment-text-multiple</v-icon>
                          </v-btn>
                        </template>
                        <span>Conversations</span>
                      </v-tooltip>
                    </div>
                  </td>
                  <td class="status-td text-center">
                    <v-tooltip left content-class="tooltip-left" v-if="v.stage === -1">
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon v-bind="attrs" v-on="on" color="">mdi-cancel</v-icon>
                      </template>
                      <span>Cancelled Request</span>
                    </v-tooltip>
                    <v-tooltip left content-class="tooltip-left" v-else-if="v.stage === 0">
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon v-bind="attrs" v-on="on" color="info">mdi-timer-sand</v-icon>
                      </template>
                      <span>Waiting Response</span>
                    </v-tooltip>
                    <v-tooltip left content-class="tooltip-left" v-else-if="v.stage === 1">
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon v-bind="attrs" v-on="on" color="warning">mdi-timer-sand</v-icon>
                      </template>
                      <span>Pending Approval</span>
                    </v-tooltip>
                    <v-tooltip left content-class="tooltip-left" v-else-if="v.stage === 2">
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon v-bind="attrs" v-on="on" color="success">mdi-check-circle</v-icon>
                      </template>
                      <span>Approved Request</span>
                    </v-tooltip>
                  </td>
                </tr>
              </template> 
            </tbody>
          </v-simple-table>
          <div v-else>
            <v-alert type="info" text border="left" :value="true">
              No Requests added yet.
            </v-alert>
          </div>
        </v-card-text>
      </v-card>
      <v-row>
        

        <v-dialog v-model="typedialog" max-width="800">
          <v-card class="">
            <v-card-text class="primary white--text d-flex align-center py-2 px-4">
              <span class="subtitle-1 white--text">Request Types</span>
              <v-spacer></v-spacer>
              <v-btn icon @click="typedialog = false;" class="ml-2 white--text"><v-icon>mdi-close</v-icon></v-btn>
            </v-card-text>
            <v-card-text class="mt-4">
              <v-simple-table dense>
                <thead>
                  <tr>
                    <th scope="type">Type</th>
                    <th scope="description">Description</th>
                  </tr>
                </thead>
                <tbody>
                  <tr class="hoverable">
                    <td><v-chip label text small>T</v-chip></td>
                    <td><span class="font-weight-bold">Text</span> - To obtain text response</td>
                  </tr>
                  <tr class="hoverable">
                    <td><v-chip label text small>N</v-chip></td>
                    <td><span class="font-weight-bold">Number</span> - To obtain number response</td>
                  </tr>
                  <tr class="hoverable">
                    <td><v-chip label text small>F</v-chip></td>
                    <td><span class="font-weight-bold">File Upload</span> - To provide a file upload option</td>
                  </tr>
                  <tr class="hoverable">
                    <td><v-chip label text small>URL</v-chip></td>
                    <td><span class="font-weight-bold">URL</span> - To provide a url input field</td>
                  </tr>
                  <tr class="hoverable">
                    <td><v-chip label text small>UL</v-chip></td>
                    <td><span class="font-weight-bold">Upload Link</span> - Link to a public cloud folder where the user can upload the files</td>
                  </tr>
                  <tr class="hoverable">
                    <td><v-chip label text small>MS</v-chip></td>
                    <td><span class="font-weight-bold">Multi Select</span> - To provide a list of points of which the user can select one or more</td>
                  </tr>
                  <tr class="hoverable">
                    <td><v-chip label text small>C</v-chip></td>
                    <td><span class="font-weight-bold">Choice</span> - To provide a list of points of which the user can select only one</td>
                  </tr>
                </tbody>
              </v-simple-table>
            </v-card-text>
          </v-card>
        </v-dialog>

        <v-dialog v-model="responsedialog" max-width="600">
          <v-card :loading="responseloading" :disabled="responseloading" loader-height="2" class="">
            <v-card-text class="primary white--text d-flex align-center py-2 px-4">
              <span class="subtitle-1 white--text" v-if="(addeditresponseheader.stage === 2)">View Response</span>
              <span class="subtitle-1 white--text" v-else>Add/Edit Response</span>
              <v-spacer></v-spacer>
              <v-btn icon @click="responsedialog = false" class="ml-2 white--text"><v-icon>mdi-close</v-icon></v-btn>
            </v-card-text>
            <v-card-text class="mt-4">
              <div class="subtitle-1 mb-2">{{addeditresponseheader.description}}</div>
              <div class="mb-2">
                <div class="d-flex align-center">
                  <v-chip label small text color="error" class="mr-2" v-if="addeditresponseheader.urgent">Urgent</v-chip>
                 
                  <v-chip label small text :color="statustext['s'+addeditresponseheader.stage].color" v-if="addeditresponseheader.stage===0">{{statustext['s'+addeditresponseheader.stage].text}}</v-chip>
                  <v-chip label small text :color="statustext['s'+addeditresponseheader.stage].color" v-else-if="addeditresponseheader.stage===1">{{statustext['s'+addeditresponseheader.stage].text}}</v-chip>
                  <v-chip label small text :color="statustext['s'+addeditresponseheader.stage].color" v-else-if="addeditresponseheader.stage===2">{{statustext['s'+addeditresponseheader.stage].text}}</v-chip>
                  <v-chip label small text :color="statustext['s'+addeditresponseheader.stage].color" v-else-if="addeditresponseheader.stage===-1">{{statustext['s'+addeditresponseheader.stage].text}}</v-chip>
                  <v-btn v-if="addeditresponseheader.type === 'fileuploadtolink'" :href="addeditresponseheader.uploadlink" target="_blank" small text class="shadow-off ml-2">Folder Link Here <v-icon right x-small>mdi-launch</v-icon></v-btn>
                </div>
                <div class="mt-4">
                  <div class="font-weight-bold mb-2 d-flex align-center">
                    <span>Response</span>
                  </div>
                  <v-checkbox v-if="addeditresponseheader.type === 'fileuploadtolink'" :disabled="[-1,2].indexOf(addeditresponseheader.stage) > -1"
                    v-model="addeditresponse.response" inset dense class="pt-0 mt-0" label="Uploaded?" 
                  ></v-checkbox>
                  <lb-string v-else-if="addeditresponseheader.type === 'text'" v-model="addeditresponse.response" label="Text" :error="addeditresponseerror.response" :disabled="[-1,2].indexOf(addeditresponseheader.stage) > -1"/>
                  <div v-else-if="addeditresponseheader.type === 'link'" class="d-flex">
                    <lb-string v-model="addeditresponse.response" label="URL Link" :error="addeditresponseerror.response" :disabled="[-1,2].indexOf(addeditresponseheader.stage) > -1"/>
                    <v-btn v-if="addeditresponse.response" text outlined class="ml-2 shadow-off" @click="$nova.copyText(addeditresponse.response)">Copy Link<v-icon right>mdi-content-copy</v-icon></v-btn>
                  </div>
                  <lb-number v-else-if="addeditresponseheader.type === 'number'" v-model="addeditresponse.response" label="Number" :error="addeditresponseerror.response" :disabled="[-1,2].indexOf(addeditresponseheader.stage) > -1"/>
                  <v-radio-group dense class="pt-0 mt-0" v-else-if="addeditresponseheader.type === 'choice'" v-model="addeditresponse.response" column :disabled="[-1,2].indexOf(addeditresponseheader.stage) > -1">
                    <v-radio v-for="cv in (addeditresponseheader.selectlist || [])" :key="cv" :label="cv" :value="cv"></v-radio>
                  </v-radio-group>
                  <div v-else-if="addeditresponseheader.type === 'multiselect'" class="mb-4" >
                    <v-checkbox dense class="pt-0" multiple :disabled="[-1,2].indexOf(addeditresponseheader.stage) > -1"
                      v-for="cv in (addeditresponseheader.selectlist || [])" :key="cv"
                      v-model="addeditresponse.response" :label="cv" :value="cv" hide-details
                    ></v-checkbox>
                  </div>
                  <div v-else-if="addeditresponseheader.type === 'fileupload'">
                    <div class="" v-if="addeditresponseheader.fileid_detail">
                      <span class="font-weight-bold pr-2">Uploaded:</span>
                      {{addeditresponseheader.fileid_detail.originalname || ""}}
                      <v-btn icon @click="$nova.downloadFile(addeditresponseheader.fileid, {pbccode: code, client: client, type: 'pbcrequest'})"><v-icon>mdi-download</v-icon></v-btn>
                    </div>
                    <lb-file :remove="true" v-model="addeditresponse.response" :error="addeditresponseerror.response" v-if="[-1,2].indexOf(addeditresponseheader.stage) === -1"/>
                  </div>
                  <lb-textarea v-model="addeditresponse.remark" label="Remark (Optional)" :error="addeditresponseerror.remark" :disabled="[-1,2].indexOf(addeditresponseheader.stage) > -1"/>
                </div>
              </div>
            </v-card-text>
            <v-card-text class="d-flex" v-if="[-1,2].indexOf(addeditresponseheader.stage) === -1">
              <v-spacer></v-spacer>
              <v-btn small color="primary" @click="updateResponse()">Update</v-btn>
            </v-card-text>
            <v-card-text v-else class="pt-0">
              <span class="font-weight-bold mr-1">Note:</span>
              <span>Response cannot be modified if the request is Closed/Cancelled/Approved</span>
            </v-card-text>
          </v-card>
        </v-dialog>

        <v-dialog v-model="commentsdialog" max-width="600" persistent>
          <v-card :loading="commentsloading" :disabled="commentsloading" loader-height="2" class="">
            <v-card-text class="primary white--text d-flex align-center py-2 px-4">
              <span class="subtitle-1 white--text">Add/View Comments</span>
              <v-spacer></v-spacer>
              <v-btn icon @click="commentsdialog = false" class="ml-2 white--text"><v-icon>mdi-close</v-icon></v-btn>
            </v-card-text>
            <v-card-text class="mt-4">
              <div v-if="(addeditresponseheader.comments || []).length === 0" class="title text-center my-5 grey--text">
                No Comments added yet
              </div>
              <div v-else>
                <div class="">
                  <perfect-scrollbar class="comments-container">
                    <template v-for="(v,k) in addeditresponseheader.comments">
                      <div :class="`d-flex align-center ${(addeditresponseheader.info_owner!==v.user)?'':'flex-row-reverse text-right'}`" :key="k">
                        <div :class="`comment-text px-4 py-2 my-2 ${(addeditresponseheader.info_owner!==v.user)?'grey lighten-2':'text-left'}`">{{v.comment}}</div>
                        <div class="mx-1 caption">
                          {{$nova.formatDate(v.created_at, "DD-MMM-YYYY, H:m", "")}}
                        </div>
                      </div>
                    </template>
                  </perfect-scrollbar>
                </div>

              </div>

            </v-card-text>
            <v-card-text class="d-flex align-center" :disabled="[-1,2].indexOf(addeditresponseheader.stage) > -1">
              <lb-string v-model="commentsdata" label="Write here..." :error="commentserror" hidedetails/>
              <v-btn small class="ml-2" color="primary" @click="addComment()">Add</v-btn>
            </v-card-text>
          </v-card>
        </v-dialog>

        <v-dialog v-model="expecteddatedialog" max-width="600" persistent>
          <v-card :loading="expecteddateloading" :disabled="expecteddateloading" loader-height="2" class="">
            <v-card-text class="primary white--text d-flex align-center py-2 px-4">
              <span class="subtitle-1 white--text">Add/View expecteddate</span>
              <v-spacer></v-spacer>
              <v-btn icon @click="expecteddatedialog = false" class="ml-2 white--text"><v-icon>mdi-close</v-icon></v-btn>
            </v-card-text>
            <v-card-text class="mt-4">
              <div class="title">
                {{addeditresponseheader.description}}
              </div>
              <div class="mb-4">
                <span class="mr-2 font-weight-bold">Requestor's Due Date:</span>{{$nova.formatDate(addeditresponseheader.duedate) || "NA"}}
              </div>
              <lb-date hidedetails v-model="expecteddatevalue" label="Your Expected Date" :error="expecteddateerror" :min="$nova.formatDateISO(new Date())" :max="$nova.formatDateISO(data.duedate) || ''"/>
            </v-card-text>
            <v-card-text class="d-flex align-center" :disabled="[-1,2].indexOf(addeditresponseheader.stage) > -1">
              <v-spacer></v-spacer>
              <v-btn small :disabled="!expecteddatevalue" class="ml-2" color="primary" @click="updateExpectedDate()">Update</v-btn>
            </v-card-text>
          </v-card>
        </v-dialog>

        <v-dialog v-model="statushelpdialog" max-width="600" persistent>
          <v-card class="">
            <v-card-text class="primary white--text d-flex align-center py-2 px-4">
              <span class="subtitle-1 white--text">Status Notations</span>
              <v-spacer></v-spacer>
              <v-btn icon @click="statushelpdialog = false" class="ml-2 white--text"><v-icon>mdi-close</v-icon></v-btn>
            </v-card-text>
            <v-card-text class="pa-4">
              <div class="my-1">
                <v-icon color="info">mdi-timer-sand</v-icon>
                <span class="ml-2">Waiting Response</span>
              </div>
              <v-divider></v-divider>
              <div class="my-1">
                <v-icon color="warning">mdi-timer-sand</v-icon>
                <span class="ml-2">Pending Approval</span>
              </div>
              <v-divider></v-divider>
              <div class="my-1">
                <v-icon color="success">mdi-check-circle</v-icon>
                <span class="ml-2">Approved Request</span>
              </div>
              <v-divider></v-divider>
              <div class="my-1">
                <v-icon color="">mdi-cancel</v-icon>
                <span class="ml-2">Cancelled Request</span>
              </div>
              <v-divider></v-divider>
            </v-card-text>
          </v-card>
        </v-dialog>

      </v-row>
    </div>
  </div>
</template>

<script>

export default {
  name: 'pbc_request_list',
  data: function(){
    return {
      code: "",
      project: "",
      client: "",
      statustext: {
        's-1': {text: 'Cancelled', color: 'error'},
        's0': {text: 'Pending', color: 'info'},
        's1': {text: 'Pending Approval', color: 'warning'},
        's2': {text: 'Closed', color: 'success'}
      },
      requesttype: {
        "text": "T - Text",
        "number": "N - Number",
        "fileupload": "F - File Upload",
        "link": "URL - URL Link",
        "fileuploadtolink": "UL - File Upload Link",
        "multiselect": "MS - Multi Select",
        "choice": "C - Choice",
      },
      totalrequests: 0,
      counter: 0,
      pendingmail: 0,
      progress: 0,
      typedialog: false,
      addeditresponseheader: {},
      addeditresponse: {},
      addeditresponseerror: {},
      responsedialog: false,
      responseloading: false,
      loading: false,
      commentsdialog: false,
      commentsloading: false,
      commentsdata: "",
      commentserror: "",
      commentreqkey: -1,
      expecteddatedialog: false,
      expecteddateloading: false,
      expecteddatevalue: "",
      expecteddatekey: "",
      expecteddateerror: "",
      data: {},
      requestlist: [],
      categorylist: ["Others"],
      categoryfilter: [],
      stagefilter: [0, 1],
      statushelpdialog: false,
    }
  },
  created() {
    this.$store.commit("headeraction", false);
  
    this.client = this.$route.params.client || "";
    this.$store.commit("setClient", this.client);
    this.refreshData();
    this.$store.commit("addLoading");
    this.$nextTick(() => {
      let menu = [
        { product: true, title: 'Information Workflow', bgcolor: 'crisk' },
        {
          title: 'Dashboard', icon: 'mdi mdi-view-dashboard-outline', href: '/pbc/requestee/view/'+this.client+'/'+this.code,
        }
      ];
      this.$store.commit("removeLoading");          
      this.$store.commit("setNewMenu", menu);
      this.$store.commit("setCustomMenu", []);
    })
  },
  activated() {
    this.refreshData();
  },
 

  methods: {
    refreshData() {
      this.code = this.$route.params.code || "";
      this.project = this.$route.params.project || "";
      this.progress = 0;
      return this.getData();
    },
    getData() {
      this.loading = true;
      return this.axios.post("/v2/pbc/project/getforrequestee/"+this.code+"/p").then(dt => {
        if(dt.data.status === "success" && dt.data.data.length > 0){
         
          let plist = dt.data.data || [];
          let cmenu = [
            { header: true, title: 'Projects' }
          ];
          for (let i = 0; i < plist.length; i++) {
            const el = plist[i];
            if(el._id === this.project) this.data = el;
            cmenu.push({title: el.name, icon: 'mdi mdi-format-list-checks', href: '/pbc/requestee/project/'+this.client+'/'+this.code+'/'+el._id})
          }
          this.$store.commit("setCustomMenu", cmenu);

          // count total request
          if(this.data.summary) this.totalrequests = this.data.summary.requested + this.data.summary.pending + this.data.summary.approved + this.data.summary.cancelled;
          this.progress = (((((this.data.summary || {}).approved || 0) + ((this.data.summary || {}).cancelled || 0))/this.totalrequests)*100 || 0).toFixed(0);
        }
        return this.axios.post("/v2/pbc/request/getforrequestee/"+this.code+"/p/"+this.project);
      }).then(dt => {
        this.requestlist = [];
        if(dt.data.status === "success"){
          if(dt.data.data.length > 0){
            this.requestlist = dt.data.data;
            // for owner colors
            this.owners = {};
            this.pendingmail = 0;
            for (let i = 0; i < this.requestlist.length; i++) {
              const el = this.requestlist[i];
              if(el.category){
                if(this.categorylist.indexOf(el.category) === -1) this.categorylist.push(el.category);
              }else el.category = "Others"
              this.owners[el['info_owner']] = {};
              if(el.mail_count === 0) this.pendingmail++;
            }
            this.categoryfilter = this.categorylist;
            let colors = this.$nova.getColors(Object.keys(this.owners).length, 0.5);
            for (let i = 0; i < Object.keys(this.owners).length; i++) {
              const el = Object.keys(this.owners)[i];
              this.owners[el].color = colors[i];
            }
          }
        }else throw new Error ("Error fetching request details");
      }).catch(err => {
        this.$store.commit("sbError", err.message || err || "Unknown error!");
        console.log(err);
      }).finally(() => {
        this.counter ++;
        this.loading = false;
      })
    },
    updateResponse() {
      this.responseloading = true;
      Promise.resolve().then(() => {
        let dta = {pbccode: this.code, client: this.client, type: "pbcrequest"}
        if(this.addeditresponseheader.type === "fileupload") return this.$nova.uploadFile(this.axios, this.addeditresponse.response, {additionaldata: dta});
      }).then(dt => {
        if(this.addeditresponseheader.type === "fileupload"){
          if(dt.data.status === "success" && dt.data.data.length > 0){
            this.addeditresponse.fileid = dt.data.data[0]._id;
          }else throw new Error ("Unable to upload file");
        }
        return this.axios.post("/v2/pbc/request/updateresponsebyrequestee/"+this.code+"/"+this.addeditresponseheader._id, {data: this.addeditresponse})
      }).then(dt => {
        if(dt.data.status === "success"){
          this.$store.commit("sbSuccess", "Response Updated");
          this.responsedialog = false;
          this.refreshData();
        }else{
          if (dt.data.data.length > 0){
            if(dt.data.data[0].index0) this.addeditresponseerror = dt.data.data[0].index0;
          }else throw new Error (dt.data.message);
        }
      }).catch(err => {
        this.$store.commit("sbError", err.message || err || "Unknown error!");
        console.log(err);
      }).finally(() => {
        this.responseloading = false;
      })
    },
    addComment() {
      this.commentsloading = true;
      this.axios.post("/v2/pbc/request/addcommentrequestee/"+this.code+"/"+this.project, {data: [{comment: this.commentsdata, request: this.addeditresponseheader._id || ''}]}).then(dt => {
        if(dt.data.status === "success"){
          if(dt.data.data.length > 0){
            this.$store.commit("sbSuccess", "Comment Added");
            this.requestlist[this.commentreqkey].comments = this.requestlist[this.commentreqkey].comments || [];
            this.requestlist[this.commentreqkey].comments.push(dt.data.data[0])
            this.addeditresponseheader = this.requestlist[this.commentreqkey];
            this.commentsdata='';
            this.commentserror='';
          }else throw new Error ("error adding comment");
        }else{
          if (dt.data.data.length > 0){
            if(dt.data.data[0].index0) this.addeditinforownererror = dt.data.data[0].index0;
          }else throw new Error (dt.data.message);
        }
      }).catch(err => {
        this.$store.commit("sbError", err.message || err || "Unknown error!");
        console.log(err);
      }).finally(() => {
        this.commentsloading = false;
      })
    },
    updateExpectedDate() {
      this.expecteddateloading = true;
      this.axios.post("/v2/pbc/request/updateexpecteddaterequestee/"+this.code+"/"+this.project+"/"+(this.addeditresponseheader._id || ''), {data: [{expecteddate: this.expecteddatevalue}]}).then(dt => {
        if(dt.data.status === "success"){
          this.$store.commit("sbSuccess", "Expected Date Updated");
          this.expecteddatedialog = false;
          this.requestlist[this.expecteddatekey].expecteddate = this.expecteddatevalue;
        }else throw new Error (dt.data.message);
      }).catch(err => {
        this.$store.commit("sbError", err.message || err || "Unknown error!");
        console.log(err);
      }).finally(() => {
        this.expecteddateloading = false;
      })
    },
    markCommentAsRead(x, k) {
      if((x.comments || []).length > 0){
        let commentkey = x.comments.length -1;
        let comment = x.comments[commentkey]; 
        if((comment.readby || []).indexOf(this.addeditresponseheader.info_owner) === -1){
          this.axios.post("/v2/pbc/request/markcommentasreadrequestee/"+this.code+"/"+x._id).then(dt => {
            if(dt.data.status === "success" && dt.data.response.nModified > 0){
              this.requestlist[k].comments[commentkey].readby.push(this.addeditresponseheader.info_owner);
            }else throw new Error (dt.data.message);
          }).catch(err => {
            this.$store.commit("sbError", err.message || err || "Unknown error!");
            console.log(err);
          }).finally(() => {
            this.commentsloading = false;
          })
        }
      }
    },
  },
  computed: {
    requesttypedropdown: function(){
      let r = [];
      for (const k in this.requesttype) {
        if (Object.hasOwnProperty.call(this.requesttype, k)) {
          const el = this.requesttype[k];
          r.push({name: el, value: k});
        }
      }
      return r;
    }
  },
  watch: {
    $route() {
      this.refreshData()
    },
  }
}
</script>

<style>
.pbc-view-table .status-td{
  max-width: 120px !important;
}
.pbc-view-table .sl-no{
  max-width: 30px !important;
}
.response-td{
  max-width: 400px !important;
}
.response-td .response-td-text{
   overflow: hidden;
   text-overflow: ellipsis;
   display: -webkit-box;
   line-clamp: 1; 
   -webkit-line-clamp: 1; /* number of lines to show */
   -webkit-box-orient: vertical;
}
</style>


<style lang="scss" scoped>
  .comment-text {
    max-width: 80%;
    border-radius: 15px;
    border: 1px solid var(--v-primary-base);
  }
  .comments-container{
    max-height: 50vh;
  }
</style>

